import React, { Component } from "react";
import "./createProfile.scss"

class CreateProfile extends Component {
  constructor() {
    super();
    this.inputRef = React.createRef();
  }
    
  handleBegin = (e) => {
    this.props.onFinish(this.inputRef.current.value);
  }

  render() {
    return (
      <div className="CreateProfile">
        <h1>Enter your name</h1>
        <input type="text" ref={this.inputRef} />

        <button onClick={this.handleBegin}>Begin</button>
      </div>
    );
  }
}

export default CreateProfile;