const CardType = {
  // The back of a card
  BACK: "BACK",

  // Weapons
  FISTS: "FISTS",
  DAGGER: "DAGGER",
  FLAIL: "FLAIL",
  BASTARD_SWORD: "BASTARD_SWORD",
  PIKE: "PIKE",
  LONGBOW: "LONGBOW",

  BARREL: "BARREL",
  DYNAMITE: "DYNAMITE",
  JAIL: "JAIL",
  MUSTANG: "MUSTANG",
  SCOPE: "SCOPE",
  BANG: "BANG",
  BEER: "BEER",
  CAT_BALOU: "CAT_BALOU",
  DUEL: "DUEL",
  GATLING: "GATLING",
  GENERAL_STORE: "GENERAL_STORE",
  INDIANS: "INDIANS",
  MISSED: "MISSED",
  PANIC: "PANIC",
  SALOON: "SALOON",
  STAGECOACH: "STAGECOACH",
  WELLS_FARGO: "WELLS_FARGO",
};

const CardAction = {
  NONE: 1,
  SELECT_PLAYER: 2,
  SELECT_CARD: 3,
};

const CardRange = {
  DISTANCE: 1,
  WEAPON_RANGE: 2,
  ANYONE: 3,
}

const getAction = (cardType) => {
  switch (cardType) {
    case CardType.BANG:
    case CardType.DYNAMITE:
    case CardType.JAIL:
    case CardType.DUEL:
      return CardAction.SELECT_PLAYER;
    case CardType.PANIC:
    case CardType.CAT_BALOU:
      return CardAction.SELECT_CARD;
    default:
      return CardAction.NONE;
  }
};

const getRange = (cardType) => {
  switch (cardType) {
    case CardType.PANIC:
      return CardRange.DISTANCE;
    case CardType.BANG:
      return CardRange.WEAPON_RANGE;
    case CardType.DYNAMITE:
    case CardType.JAIL:
    case CardType.DUEL:
    case CardType.CAT_BALOU:
      return CardRange.ANYONE;
    default:
      return -1;
  }
}

export { CardType, CardAction, getAction, getRange };