import React, { Component } from "react";
import seedrandom from "seedrandom";
import "./deck.scss";

class Deck extends Component {
  constructor() {
    super();

    this.deckRef = React.createRef();
  }

  updatePosition = () => {
    if (this.props.onPositionUpdate === undefined) return;

    const rect = this.deckRef.current.getBoundingClientRect();
    this.props.onPositionUpdate(rect.x, rect.y);
  }

  componentDidMount() {
    this.updatePosition();
  }

  componentDidUpdate() {
    this.updatePosition();
  }
  
  render() {
    const rng = seedrandom(this.props.className);

    return (
      <div
        className={`Deck ${this.props.className} ${
          this.props.selectable ? "selectable" : ""
        }`}
        ref={this.deckRef}
        onClick={() => this.props.onDeckSelect()}
      >
        {this.props.cards.map((card, i) => {
          const offsetX = rng() * 10 - 5;
          const offsetY = rng() * 10 - 5;
          const rotation = rng() * 5 - 2.5;

          return (
            <img
              key={i}
              alt='Playing card'
              src={`/img/cards/${this.props.reveal ? card.cardType.toLowerCase() : 'back'}.png`}
              style={{
                transform:
                  "rotate(" +
                  rotation +
                  "deg) translate(" +
                  offsetX +
                  "px, " +
                  offsetY +
                  "px)",
              }}
            />
          );
        })}
      </div>
    );
  }
}

export default Deck;
