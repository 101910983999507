import SockJS from "sockjs-client";
import Stomp from "stompjs";

class Socket {
  constructor() {
    this.code = '';
    this.conn = null;
    this.connected = false;
    this.subscribers = new Map();
    this.pendingSubscriptions = [];
  }

  start() {
    if (this.conn !== null) {
      return;
    }

    const socket = new SockJS("https://bang-cards.herokuapp.com/ws");
    this.conn = Stomp.over(socket);
    this.conn.connect({}, (frame) => {
      this.connected = true;

      this.pendingSubscriptions.forEach((subscriber) => {
        this.conn.subscribe(subscriber.channel, (msg) => {
          subscriber.handler(JSON.parse(msg.body));
        });
      });

      if (this.onopen !== undefined) {
        this.onopen();
      }
    });
  }

  send(channel, data = {}) {
    this.conn.send(channel, {}, JSON.stringify(data));
  }

  subscribe = (channel, handler) => {
    if (!channel.includes("/")) {
      channel = `/game/${this.code}/${channel}`;
    }

    if (this.conn === null || !this.conn.connected) {
      this.pendingSubscriptions.push({
        channel: channel,
        handler: handler,
      });

      return;
    }

    this.conn.subscribe(channel, (msg) => {
      handler(JSON.parse(msg.body));
    });
  };
}

export default new Socket();
