import { Component } from "react";
import "./lobby.scss";

class Lobby extends Component {
  render() {
    return (
      <div className="Lobby">
        <div className="table" />
        {this.props.game.players.map((player, i) => {
          const theta = Math.PI / 2 - 2 * i * Math.PI / 7;

          return (
            <>
              <p style={(() => {
                const r = 175;
                const x = window.innerWidth / 2 + r * Math.cos(theta);
                const y = window.innerHeight / 2 - r * Math.sin(theta);
                return {top: `${y}px`, left: `${x}px`};
              })()}>
                {player.name}
              </p>
              <img alt="Sword" src="/img/ui/sword.png" style={(() => {
                const r = 100;
                const x = window.innerWidth / 2 + r * Math.cos(theta);
                const y = window.innerHeight / 2 - r * Math.sin(theta);
                return {top: `${y}px`, left: `${x}px`, transform: `translate(-50%, -50%) rotate(${-theta}rad)`};
              })()} />
            </>
          );
        })}
        <button onClick={this.props.onStart}>Start</button>
      </div>
    );
  }
}

export default Lobby;