import React, { Component } from "react";
import "./enterCode.scss"

class EnterCode extends Component {
  constructor() {
    super();

    this.inputRef = React.createRef();
  }

  handleContinue = () => {
    this.props.onFinish(this.inputRef.current.value.toLowerCase());
  }

  render() {
    return (
      <div className="EnterCode">
        <h1>Enter game code</h1>
        <input
          autoComplete={false}
          autoCorrect={false}
          autoCapitalize={false}
          spellCheck={false}
          type="text"
          ref={this.inputRef}
        />
        <button onClick={this.handleContinue}>Continue</button>
      </div>
    );
  }
}

export default EnterCode;