import { Component } from "react";
import styled, { css, keyframes } from "styled-components";
import "./flyingCard.scss";

const flip = (cardType) => keyframes`
  0% {
    background: url(/img/cards/back.png);
  }
  
  49.999% {
    background: url(/img/cards/back.png);
  }
  
  50.001% {
    background: url(/img/cards/${cardType}.png);
  }
  
  100% {
    background: url(/img/cards/${cardType}.png);
    transform: translateX(calc(100% + 48px)) rotate3d(0, 1, 0, 180deg) scaleX(-1);
  }
`;

const Card = styled.div`
  top: ${props => props.y * 100}vh;
  left: ${props => props.x * 100}vw;
  animation: ${props => props.flip ? css`${flip(props.cardType)} 0.5s ease-in-out forwards` : 'none'}
`;

class FlyingCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fly: false,
      flip: false,
      x: props.startX,
      y: props.startY,
    };
  }

  componentDidMount() {
    if (this.props.flip) {
      this.setState({
        flip: true
      });
    } else {
      setTimeout(() => {
        this.setState({
          fly: true,
          x: this.props.endX,
          y: this.props.endY,
        });
      }, this.props.delay);
    }
  }

  render() {
    return (
      <Card
        className={`FlyingCard ${this.state.flip ? 'flipping' : ''} ${this.state.fly ? 'flying' : ''}`}
        x={this.state.x}
        y={this.state.y}
        flip={this.state.flip}
        cardType={this.props.cardType}
      />
    );
  }
}

export default FlyingCard;
