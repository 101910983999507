import React, { Component } from "react";
import Hand from "./hand";
import "./player.scss";

import { CardLocation } from "../game/cardLocation";

class Player extends Component {
  constructor() {
    super();

    this.state = {
      theta: 0,
    };
  }

  render() {
    let character = "none";
    let characterNameCapitalized = [];

    if (this.props.player.character !== null) {
      character = this.props.player.character.toLowerCase();

      character.split("_").forEach((word) => {
        if (word === "the") {
          word = word.toLowerCase();
        } else {
          word = word.substr(0, 1).toUpperCase() + word.substr(1, word.length);
        }

        characterNameCapitalized.push(word);
      });

      characterNameCapitalized = characterNameCapitalized.join(" ");
    }

    // let gun = "none";

    // if (this.props.player.gun !== null) {
    //   gun = this.props.player.gun.toLowerCase();
    // }

    return (
      <div
        className='Player'
        style={{
          top: `${this.props.y * 100}vh`,
          left: `${this.props.x * 100}vw`,
        }}
      >
        <div className="hearts-container">
          <p>{this.props.player.name} ({characterNameCapitalized})</p>
          {Array(this.props.player.maxBullets).fill().map((_, i) => <img alt="Heart" key={i} src={`/img/icons/${i < this.props.player.bullets ? 'heart' : 'heartEmpty'}.svg`} />)}
        </div>
        {this.props.selectable ? (
          <button className={`select-button ${this.props.selected ? 'selected' : ''}`} onClick={() => this.props.onPlayerSelect(this.props.player.id)}>{this.props.selected ? 'Selected' : 'Select'}</button>
        ) : (
          <>
            <div className='top-row'>
              <img
                alt={characterNameCapitalized}
                className='character'
                src={`/img/characters/${character}.png`}
              />
              <Hand
                cards={this.props.player.hand}
                selectedCardIndices={this.props.cardSelectable ? (this.props.selectedCardLocation === CardLocation.HAND ? [this.props.selectedCardIndex] : []) : [-1]}
                onCardSelect={(_, i) => this.props.onPlayerCardSelect(i, CardLocation.HAND)} />
            </div>
            <div className="bottom-row">
              {this.props.player.gun !== null ? (
                <img
                  alt={this.props.player.gun.cardType}
                  className='gun'
                  src={`/img/cards/${this.props.player.gun.cardType.toLowerCase()}.png`}
                />
              ) : null}
              {this.props.player.inPlayCards.map((card) => (
                <img
                  alt={card.cardType}
                  className='gun'
                  src={`/img/cards/${card.cardType.toLowerCase()}.png`}
                />
              ))}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Player;
