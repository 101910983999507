import { Component } from "react";

class Card extends Component {
  render() {
    return <img
      className="Card"
      alt={this.props.card === null ? "Missing card" : this.props.card.cardType}
      src={this.props.card === null ? null : `/img/cards/${this.props.card.cardType.toLowerCase()}.png`}
      style={{
        cursor: this.props.onClick === undefined ? 'initial' : 'pointer'
      }}
      onClick={() => this.props.onClick(this.props.card)} />;
  }
}

export default Card;