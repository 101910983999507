import React, { Component } from "react";
import GameContainer from "./components/gameContainer";
import Homepage from "./components/homepage";
import socket from "./socket";
import './App.css';

import { HashRouter, Switch, Route } from "react-router-dom";

class App extends Component {
  constructor() {
    super();

    this.state = {
      playerId: null
    };
  }

  componentDidMount() {
    socket.subscribe("/user/id", (msg) => {
      this.setState({
        playerId: msg.id
      });
    });

    socket.start();
  }

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route
            path="/:code"
            render={(props) => (
              <GameContainer {...props} playerId={this.state.playerId} />
            )}
          />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
