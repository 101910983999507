const Phase = {
  DRAW: "DRAW",
  PLAY: "PLAY",
  DISCARD: "DISCARD",
  SHOT: "SHOT",
  GENERAL_STORE: "GENERAL_STORE",
  DEFUSING: "DEFUSING",
  JAILED: "JAILED",
  DUEL: "DUEL",
  BARREL: "BARREL",
  INDIANS: "INDIANS",
  GAME_OVER: "GAME_OVER",
  ZERO_LIVES: "ZERO_LIVES"
};

export { Phase };