import React, { Component } from "react";
import styled from "styled-components";
import socket from "../socket";
import Hand from "./hand";
import Player from "./player";
import Deck from "./deck";
import Card from "./card";

import { CardAction, CardType, getAction } from "../game/cardType";
import { Phase } from "../game/phase";

import "./game.scss";
import FlyingCard from "./flyingCard";

const Table = styled.div`
  background: #eee;
  border-radius: 40vh;
  height: 80vh;
  width: 80vw;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StartButton = styled.button`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
`;

class Game extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCardAction: -1,

      // Index of the card that the player might play from their hand
      selectedCardIndices: [],

      // Index of the player that the player might play their card on
      selectedPlayerIndex: -1,

      // Index of the card that the player could select from another player (e.g. for Cat Balou)
      selectedOtherCardIndex: -1,
      selectedOtherCardLocation: "",

      drawPileRect: {
        x: 0,
        y: 0
      }
    };

    this.drawPile = React.createRef();

    this.handleCardSelect = this.handleCardSelect.bind(this);
  }

  componentDidMount() {
    socket.code = this.props.code;

    socket.subscribe("/user/error", (msg) => {
      console.error(msg);
    });

    socket.subscribe("draw", (msg) => {
      this.setState({
        drawCards: {
          cards: msg.cards,
          recipient: msg.playerId,
        },
      });

      setTimeout(() => {
        this.setState({
          drawCards: undefined
        });
      }, 1000);
    });

    socket.subscribe("play", (_) => {
      this.setState({
        selectedCardAction: -1,
        selectedCardIndices: [],
        selectedPlayerIndex: -1,
        selectedOtherCardIndex: -1,
        selectedOtherCardLocation: ""
      });
    });

    socket.subscribe("discard", (_) => {
      this.setState({
        selectedCardAction: -1,
        selectedCardIndices: [],
        selectedPlayerIndex: -1,
        selectedOtherCardIndex: -1,
        selectedOtherCardLocation: ""
      });
    });
  }

  isMyTurn() {
    return this.props.player.id === this.props.game.players[this.props.game.turnPlayerIndex].id;
  }

  calculatePosition = (theta) => {
    const rLarge = window.innerWidth * 0.4;
    const rSmall = window.innerHeight * 0.4;
    const criticalTheta = Math.atan2(rSmall, rLarge - rSmall);

    const centerX = window.innerWidth * 0.5;
    const centerY = window.innerHeight * 0.5;
    let top;
    let left;

    if (theta < criticalTheta) {
      let scaledTheta = (Math.PI / 2 / criticalTheta) * theta;
      top = centerY + rSmall * Math.sin(-scaledTheta);
      left = centerX + (rLarge - rSmall) + rSmall * Math.cos(scaledTheta);
    } else if (theta < Math.PI - criticalTheta) {
      top = centerY - rSmall;
      left =
        centerX +
        (rLarge - rSmall) -
        (2 * (rLarge - rSmall) * (theta - criticalTheta)) /
          (Math.PI - 2 * criticalTheta);
    } else if (theta < Math.PI + criticalTheta) {
      let scaledTheta =
        Math.PI / 2 +
        ((theta - (Math.PI - criticalTheta)) * Math.PI) / (2 * criticalTheta);
      top = centerY + rSmall * Math.sin(-scaledTheta);
      left = centerX - (rLarge - rSmall) + rSmall * Math.cos(scaledTheta);
    } else if (theta < 2 * Math.PI - criticalTheta) {
      top = centerY + rSmall;
      left =
        centerX -
        (rLarge - rSmall) +
        (2 * (rLarge - rSmall) * (theta - (Math.PI + criticalTheta))) /
          (Math.PI - 2 * criticalTheta);
    } else if (theta < 2 * Math.PI) {
      let scaledTheta =
        -Math.PI / 2 +
        ((theta - (2 * Math.PI - criticalTheta)) * Math.PI) /
          (2 * criticalTheta);
      top = centerY + rSmall * Math.sin(-scaledTheta);
      left = centerX + (rLarge - rSmall) + rSmall * Math.cos(scaledTheta);
    }

    top /= window.innerHeight;
    left /= window.innerWidth;

    return {
      x: left,
      y: top,
    };
  };

  handleCardSelect = (card, i) => {
    const allowedPhases = [Phase.PLAY, Phase.DISCARD, Phase.SHOT, Phase.DUEL, Phase.INDIANS, Phase.ZERO_LIVES];

    if (!this.isMyTurn() && !allowedPhases.includes(this.props.game.phase)) {
      return;
    }

    const newState = {
      // Reset selections when selecting a new card
      selectedPlayerIndex: -1,
      selectedOtherCardIndex: -1,
      selectedOtherCardLocation: ""
    };

    if (this.props.game.phase === Phase.DISCARD) {
      if (this.state.selectedCardIndices.includes(i)) {
        newState.selectedCardIndices = this.state.selectedCardIndices.filter((x) => x !== i);
      } else if (this.state.selectedCardIndices.length < this.props.player.hand.length - this.props.player.bullets) {
        // Make sure we don't add cards past what we need to discard
        newState.selectedCardIndices = this.state.selectedCardIndices.concat(i);
      }
    } else {
      if (this.state.selectedCardIndices.includes(i)) {
        newState.selectedCardAction = CardAction.NONE;
        newState.selectedCardIndices = [];
      } else {
        newState.selectedCardAction = getAction(card.cardType);
        newState.selectedCardIndices = [i];
      }
    }

    if (this.props.game.phase === Phase.SHOT) {
      // In shot phase, can only play missed, or beer if losing last life point
      if (card.cardType !== CardType.MISSED && !(card.cardType === CardType.BEER && this.props.player.bullets === 0)) {
        return;
      }
    } else if (this.props.game.phase === Phase.ZERO_LIVES) {
      if (card.cardType !== CardType.BEER) {
        return;
      }
    } else if (this.props.game.phase === Phase.DUEL || this.props.game.phase === Phase.INDIANS) {
      // In duel phase, can only play bang cards
      if (card.cardType !== CardType.BANG) {
        return;
      }

      newState.selectedCardAction = CardAction.NONE;
    }

    this.setState(newState);
  };

  handleDeckSelect = () => {
    const allowedPhases = [Phase.DRAW, Phase.JAILED, Phase.DEFUSING, Phase.BARREL];

    if (!this.isMyTurn() || !allowedPhases.includes(this.props.game.phase)) {
      return;
    }

    socket.send(`/app/draw/${this.props.code}`);
  };

  handleDiscardSelect = () => {
    const allowedPhases = [Phase.DISCARD];

    if (!this.isMyTurn() || !allowedPhases.includes(this.props.game.phase)) {
      return;
    }

    socket.send(`/app/discard/${this.props.code}`, {
      cardIndices: this.state.selectedCardIndices
    });
  }

  handlePlayerSelect = (playerId) => {
    let newPlayerIndex = this.props.game.players.findIndex((player) => player.id === playerId);

    if (newPlayerIndex === this.state.selectedPlayerIndex) {
      newPlayerIndex = -1;
    }

    this.setState({
      selectedPlayerIndex: newPlayerIndex
    });
  }

  handlePlayerCardSelect = (idx, location) => {
    if (this.state.selectedCardAction !== CardAction.SELECT_CARD) {
      return;
    }

    this.setState({
      selectedOtherCardIndex: idx,
      selectedOtherCardLocation: location
    });
  }

  handleSelectableCard = (card) => {
    let cardIndex = 0;

    for (let i = 0; i < this.props.game.selectableCards.length; i++) {
      if (this.props.game.selectableCards[i] === card) {
        cardIndex = i;
        break;
      }
    }

    if (this.props.game.selectableCards[cardIndex] === null) {
      return;
    }

    socket.send(`/app/select/${this.props.code}`, {
      cardIndices: [cardIndex]
    });
  }

  render() {
    let flyingPos;

    if (this.state.drawCards !== undefined) {
      const flyingPlayerIndex = this.props.game.players.findIndex(
        (player) => player.id === this.state.drawCards.recipient
      );
      const theta = (Math.PI / 4) * flyingPlayerIndex;
      flyingPos = this.calculatePosition(theta);
    }

    console.log(this.state.drawPileRect);

    return (
      <div className='Game'>
        <Table>
          {this.props.game.inProgress ? (
            <div className="table-contents">
              <div className="decks-container">
                {this.props.game.phase === Phase.GENERAL_STORE ?
                  this.props.game.selectableCards.map((card) => <Card card={card} onClick={this.handleSelectableCard} />) : (
                  <>
                    <Deck
                      className='deck'
                      ref={this.drawPile}
                      cards={this.props.game.deck.drawPile}
                      selectable={this.isMyTurn() && [Phase.DRAW, Phase.JAILED, Phase.DEFUSING, Phase.BARREL].includes(this.props.game.phase)}
                      reveal={false}
                      onDeckSelect={this.handleDeckSelect}
                      onPositionUpdate={(x, y) => {
                        if (this.state.drawPileRect.x === x && this.state.drawPileRect.y === y) return;
                        this.setState({ drawPileRect: { x, y } });
                      }}
                    />

                    {/* Discard pile */}
                    <Deck
                      className='discard-pile'
                      cards={this.props.game.deck.discardPile}
                      selectable={this.isMyTurn() && this.props.game.phase === Phase.DISCARD}
                      reveal={true}
                      onDeckSelect={this.handleDiscardSelect}
                    />
                  </>
                )}
              </div>
              <p className='instructions'>
                {(() => {
                  if (this.isMyTurn()) {
                    if (this.props.game.phase === Phase.DRAW) {
                      return <><strong>Your turn!</strong> Click on the deck to draw your cards.</>;
                    } else if (this.props.game.phase === Phase.PLAY) {
                      return <><strong>Your turn!</strong> Click one of your cards to play it.</>;
                    } else if (this.props.game.phase === Phase.DISCARD) {
                      const numCards = this.props.player.hand.length - this.props.player.bullets;
                      return <><strong>Your turn!</strong> You have {this.props.player.bullets} lives, so you need to discard {numCards} {numCards > 1 ? 'cards' : 'card'}. Select the {numCards > 1 ? 'cards' : 'card'} that you'd like to discard, and click on the discard pile.</>;
                    } else if (this.props.game.phase === Phase.JAILED) {
                      return <><strong>Your turn!</strong> You're in jail. Draw a Heart card to escape!</>;
                    } else if (this.props.game.phase === Phase.DEFUSING) {
                      return <><strong>Your turn!</strong> There's dynamite in front of you. Draw a 2-9 of Spades to pass it to the next player, or else it'll explode!</>;
                    } else if (this.props.game.phase === Phase.SHOT) {
                      return <><strong>You've been shot!</strong> Play a missed card to dodge the bullet.</>;
                    } else if (this.props.game.phase === Phase.DUEL) {
                      return <><strong>You're in a duel!</strong> Play a bang card to continue the duel.</>;
                    } else if (this.props.game.phase === Phase.BARREL) {
                      return <><strong>You've been shot</strong>, but you have a barrel! Check the next card to see if the shot missed.</>;
                    } else if (this.props.game.phase === Phase.INDIANS) {
                      return <><strong>Indians has been played!</strong> Play a bang card to avoid losing a life.</>;
                    } else if (this.props.game.phase === Phase.ZERO_LIVES) {
                      return <><strong>You've lost your last life point!</strong> Play a beer card to regain one life point, or end your turn to admit defeat.</>;
                    }
                  } else {
                    return <>{this.props.game.players[this.props.game.turnPlayerIndex].name} is currently taking their turn.</>;
                  }
                })()}
              </p>
              {this.isMyTurn() && [Phase.PLAY, Phase.SHOT, Phase.DUEL, Phase.INDIANS, Phase.ZERO_LIVES].includes(this.props.game.phase) ? (
                <button
                  className="turn-button"
                  disabled={(() => {
                    if (this.state.selectedCardAction === CardAction.SELECT_PLAYER && this.state.selectedPlayerIndex === -1) {
                      // Player needs to select a player but hasn't yet, so disable the button
                      return true;
                    } else if (this.state.selectedCardAction === CardAction.SELECT_CARD && (this.state.selectedOtherCardLocation === "" || this.state.selectedOtherCardIndex === -1)) {
                      // Player needs to select a card but hasn't yet
                      return true;
                    }

                    return false;
                  })()}
                  onClick={() => {
                    if (this.state.selectedCardIndices.length === 0) {
                      socket.send(`/app/end/${this.props.code}`);
                    } else {
                      socket.send(`/app/play/${this.props.code}`, {
                        cardIndex: this.state.selectedCardIndices[0],
                        targetPlayerIndex: this.state.selectedPlayerIndex,
                        targetCardIndex: this.state.selectedOtherCardIndex,
                        targetCardLocation: this.state.selectedOtherCardLocation,
                      });
                    }
                  }}
                >{this.state.selectedCardIndices.length === 0 ? "End Turn" : "Play"}</button>
              ) : null}
            </div>
          ) : (
            <StartButton onClick={() => socket.send(`/app/start/${this.props.code}`, {})}>
              Start
            </StartButton>
          )}
        </Table>
        {this.props.game.players.map((player, i) => {
          const { x, y } = this.calculatePosition((Math.PI / 4) * i);

          return (
            <Player
              key={i}
              cardSelectable={this.state.selectedCardAction === CardAction.SELECT_CARD}
              selectable={(() => {
                if (this.state.selectedCardAction === -1 || this.state.selectedCardAction === CardAction.NONE) {
                  // If a card isn't being played, players can't be selected
                  return false;
                }

                if (this.state.selectedCardAction === CardAction.SELECT_CARD) {
                  // If we're selecting a card, a player needs to be selected first
                  return this.state.selectedPlayerIndex === -1;
                }

                // Otherwise, we need to select a player
                return true;
              })()}
              selected={this.state.selectedPlayerIndex === i}
              selectedCardIndex={this.state.selectedPlayerIndex === i ? this.state.selectedOtherCardIndex : -1}
              selectedCardLocation={this.state.selectedPlayerIndex === i ? this.state.selectedOtherCardLocation : -1}
              player={player}
              x={x}
              y={y}
              onPlayerSelect={this.handlePlayerSelect}
              onPlayerCardSelect={this.handlePlayerCardSelect}
            />
          );
        })}
        <Hand
          cards={this.props.player.hand}
          main={true}
          selectedCardIndices={this.state.selectedCardIndices}
          onCardSelect={this.handleCardSelect}
        />
        {this.state.drawCards === undefined
          ? null
          : this.state.drawCards.cards
            .map((card, i) => (
              <FlyingCard
                key={i}
                cardType={card.cardType.toLowerCase()}
                flip={[Phase.JAILED, Phase.DEFUSING, Phase.BARREL].includes(this.props.game.phase)}
                startX={this.state.drawPileRect.x / window.innerWidth}
                startY={this.state.drawPileRect.y / window.innerHeight}
                endX={flyingPos.x}
                endY={flyingPos.y}
                delay={i * 250}
              />
            ))}
      </div>
    );
  }
}

export default Game;
