import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import EnterCode from "./enterCode";
import socket from "../socket";
import "./homepage.scss"

class Homepage extends Component {
  constructor() {
    super();
    
    this.state = {
      code: null,
      codeEntry: false
    };
  }

  componentDidMount() {
    socket.start();
  }

  handleCodeEntry = (code) => {
    this.setState({
      code,
      codeEntry: false
    });
  };

  handleJoinGame = () => {
    this.setState({
      codeEntry: true
    });
  };

  handleStartGame = () => {
    socket.subscribe("/user/game", (msg) => {
      this.setState({
        code: msg.game.code
      });
    });
    
    socket.send(`/app/join`);
  };

  render() {
    if (this.state.codeEntry) {
      return <EnterCode onFinish={this.handleCodeEntry} />
    } else if (this.state.code !== null) {
      return <Redirect to={`/${this.state.code}`} />
    }

    return (
      <div className="Homepage">
        <h1>Stab</h1>
        <button onClick={this.handleStartGame}>Start Game</button>
        <button onClick={this.handleJoinGame}>Join Game</button>
      </div>
    );
  }
}

export default Homepage;