import React, { Component } from "react";
import "./hand.scss";

class Hand extends Component {
  render() {
    const startingRotation = 20;

    if (this.props.cards === null) {
      return <div className="Hand" />;
    }

    return (
      <div className={`Hand${this.props.main ? " main" : ""}`}>
        {this.props.cards.map((x, i) => (
          <img
            alt={x.cardType.toLowerCase()}
            className={this.props.selectedCardIndices.includes(i) ? "selected" : ""}
            key={i}
            src={`/img/cards/${this.props.main ? x.cardType.toLowerCase() : "back"}.png`}
            style={{
              filter:
                this.props.selectedCardIndices.length > 0 &&
                !this.props.selectedCardIndices.includes(i)
                  ? "grayscale(100%) contrast(30%)"
                  : "none",
              transform:
                this.props.cards.length === 1 ? "none" : "rotate(" +
                (-startingRotation +
                  (i * (startingRotation * 2)) /
                    (this.props.cards.length - 1)) +
                "deg)",
              transformOrigin:
                i < this.props.cards.length / 2 ? "top right" : "top left",
            }}
            onClick={() => this.props.onCardSelect(x, i)}
          />
        ))}
      </div>
    );
  }
}

export default Hand;
