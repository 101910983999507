import { Component } from "react";
import CreateProfile from "./createProfile";
import Game from "./game";
import Lobby from "./lobby";
import socket from "../socket";

class GameContainer extends Component {
  constructor() {
    super();

    this.state = {
      game: {
        deck: {
          drawPile: [],
          discardPile: []
        },
        inProgress: false,
        players: [],
        phase: 1,
        turnPlayerIndex: 0,
      },
      player: {
        character: "none",
        hand: [],
      },
      name: "",
    };
  }

  componentDidMount() {
    const updateGame = (msg) => {
      const newState = { game: msg.game };

      if (msg.game.players.length > 0 && this.props.playerId.length > 0) {
        newState.player = msg.game.players[msg.game.players.findIndex((player) => player.id === this.props.playerId)];
      }

      this.setState(newState);
    };

    socket.subscribe(`/game/${this.props.match.params.code}`, updateGame);
    socket.subscribe("/user/game", updateGame);

    socket.start();
  }

  handleProfileFinish = (name) => {
    socket.send(`/app/join/${this.props.match.params.code}`, { name });
    this.setState({ name });
  }

  handleGameStart = () => {
    socket.send(`/app/start/${this.props.match.params.code}`);
  }

  render() {
    if (this.state.game.inProgress) {
      return <Game code={this.props.match.params.code} game={this.state.game} player={this.state.player} />;
    } else if (this.state.name.length === 0) {
      return <CreateProfile onFinish={this.handleProfileFinish} />;
    } else {
      return <Lobby code={this.props.match.params.code} game={this.state.game} player={this.state.player} onStart={this.handleGameStart} />;
    }
  }
}

export default GameContainer;